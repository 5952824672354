/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

		// Ease in
	 	$(".loader").fadeOut(350);

    // Changing the defaults
    window.sr = ScrollReveal({ reset: false });

    // Customizing a reveal set
    sr.reveal('.fadein', {
      duration: 800,
      scale: 0.9,
      distance: '100px'
    });

    //Parallax
    function simpleParallax() {
        //This variable is storing the distance scrolled
        var scrolled = $(window).scrollTop() + 1;

        //Every element with the class "scroll" will have parallax background
        //Change the "0.3" for adjusting scroll speed.
        $('.scroll').css('background-position', '0' + ' ' + -(scrolled * 0.1) + 'px');
    }
    //Everytime we scroll, it will fire the function
    $(window).scroll(function (e) {
        simpleParallax();
    });

		/*
		$("[rel='single_2']").fancybox({
			maxWidth	: 1000,
			maxHeight	: 600,
			width  : '800px',
			height : '600px',
			fitToView	: true,

					caption : {
						type : 'inside'
					},
					openEffect  : 'fade',
					closeEffect : 'fade',
					nextEffect  : 'fade',
					prevEffect  : 'fade',
					iframe : {
        				preload: false
    					}
				});
				*/

	//popover
	$('[data-toggle="popover"]').popover({trigger: 'hover'});

		//Equal Height
        (function($) {
          "use strict";
          $.fn.equalHeights = function(widthThreshold) {
            var self = this,
              nodeObjects = [],
              heights = [],
              tallest;
            $(window).on("load resize", function() {
              self.children().each(function(i) {
                $(this).css("height", "");
                var height = $(this).height(),
                  paddingTop = Math.ceil(parseFloat($(this).css("padding-top"))),
                  paddingBottom = Math.ceil(parseFloat($(this).css("padding-bottom"))),
                  borderTop = Math.ceil(parseFloat($(this).css("border-top-width"))),
                  borderBottom = Math.ceil(parseFloat($(this).css("border-bottom-width"))),
                  totalHeight = height + paddingTop + paddingBottom + borderBottom + borderTop;
                nodeObjects[i] = {
                  height: height,
                  paddingTop: paddingTop,
                  paddingBottom: paddingBottom,
                  borderTop: borderTop,
                  borderBottom: borderBottom,
                  totalHeight: totalHeight
                };
                heights[i] = totalHeight;
              });
            });
            $(window).on("load resize", function() {
              if (widthThreshold && $(window).width() < widthThreshold) {
                return false;
              }
              self.children().each(function(i) {
                var diff,
                  oldHeight = $(this).height(),
                  newHeight;
                tallest = Math.max.apply(Math, heights);
                diff = tallest - nodeObjects[i].totalHeight;
                newHeight = oldHeight + diff;
                $(this).height(newHeight);
              });
            });
          };
        }(jQuery));
        //EQ Hieghts
        $(".equalHeights").equalHeights();

		//slidedown mega menu




		$(document).ready(function() {
    $('.nav li')
        .hover(function() {
           $(this).children('.drop-down').stop().slideDown(200);
        }, function() {
            $(this).children('.drop-down').stop().slideUp(200);
        });
});



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
		$('.bxslider').bxSlider({  infiniteLoop: false,  pagerCustom: '#bx-pager',  adaptiveHeight: true, preloadImages: 'all', touchEnabled: false});

		$('.bxslider1').bxSlider({auto: true, pause: 7000, infiniteLoop: false, pagerCustom: '#bx-pager1',  adaptiveHeight: true, preloadImages: 'all' });

		$('.bxslider2').bxSlider({infiniteLoop: false, pagerCustom: '#bx-pager2',  adaptiveHeight: true, preloadImages: 'all' });
	 },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // tv-equipementlist page
    'tv_equipementlist': {
      init: function() {
        // JavaScript to be fired on the about us page

      }
    },

	 // resource-guide page
    'single_city_steel': {
      init: function() {
        // JavaScript to be fired on the resource-guide page



var stickyTop = $('#stickyBanner').offset().top;

$(window).on( 'scroll', function(){
        if ($(window).scrollTop() >= stickyTop) {

			$('#stickyBanner').addClass("fixed");

        } else {
            $('#stickyBanner').removeClass("fixed");
        }
    });

function scrollNav() {
  $('.anchors a').click(function(){
    //Toggle Class
    $(".active").removeClass("active");
    $(this).closest('li').addClass("active");
    var theClass = $(this).attr("class");
    $('.'+theClass).parent('li').addClass('active');
    //Animate
    $('html, body').stop().animate({
        scrollTop: $( $(this).attr('href') ).offset().top - 160
    }, 400);
    return false;
  });
  $('.scrollTop a').scrollTop();
}
scrollNav();


		 }
    },

	   'page_template_template_get_quote': {
      init: function() {
		 //hide all inputs except the first one
    $('p.addFile').not(':eq(0)').hide();

    //functionality for add-file link
    $('a.add_file').on('click', function(e){
      //show by click the first one from hidden inputs
      $('p.addFile:not(:visible):first').show('slow');
      e.preventDefault();
    });

    //functionality for del-file link
    $('a.del_file').on('click', function(e){
      //var init
      var input_parent = $(this).parent();
      var input_wrap = input_parent.find('span');
      //reset field value
      input_wrap.html(input_wrap.html());
      //hide by click
      input_parent.hide('slow');
      e.preventDefault();
    });

	//END

	  }
	   },



	 'request_for_quote': {
      init: function() {
        // JavaScript to be fired on the request-for-quote page


        console.log('HELLO');

        $(".serviceType").on("change",function(){
            var selVal = $(this).val();
            console.log(selVal);

            if(selVal == 'Atmosphere Heat Treat') {
              $('.service-message').html('<div class="alert alert-info" role="alert">'+selVal+' will take 6 days to respond</div>');
            } else if(selVal == 'Hydrogen Heat Treat') {
              $('.service-message').html('<div class="alert alert-info" role="alert">'+selVal+' will take 2 days to respond</div>');
            } else {
              $('.service-message').html('');
            }


        });


 //  TypeofWork

	//hide all inputs except the first one
    $('p.addFile').not(':eq(0)').hide();

    //functionality for add-file link
    $('a.add_file').on('click', function(e){
      //show by click the first one from hidden inputs
      $('p.addFile:not(:visible):first').show('slow');
      e.preventDefault();
    });

    //functionality for del-file link
    $('a.del_file').on('click', function(e){
      //var init
      var input_parent = $(this).parent();
      var input_wrap = input_parent.find('span');
      //reset field value
      input_wrap.html(input_wrap.html());
      //hide by click
      input_parent.hide('slow');
      e.preventDefault();
    });

	//END

	// Function to replace inputs
	function fileInput(fi_container_class, fi_button_class, fi_filename_class, fi_button_text) {

	// Arguments
	fi_container_class	=	fi_container_class	||	'fileUpload'; // Classname of the wrapper that contains the button & filename.
	fi_button_class		=	fi_button_class		||	'fileBtn'; // Classname for the button
	fi_filename_class	=	fi_filename_class	||	'fileName'; // Name of the text element's class
	fi_button_text		=	fi_button_text		||	'Choose file'; // Text inside the button

	// Variables
	var fi_file = $('input[type=file]'); // Type of input to look for

	// Hide file inputs
	fi_file.css('display', 'none');

	// String to append
	var fi_str = '<div class="'+fi_container_class+'"><div class="'+fi_button_class+'">'+fi_button_text+'</div><div class="'+fi_filename_class+'"></div></div>';
	// Append "fake input" after the original input (which have been hidden)
	fi_file.after(fi_str);

	// Count amount of inputs
	var fi_count = fi_file.length;
	// Loop while "count" is greater than or equal to "i".
	for (var i = 1; i <= fi_count; i++) {
		// Get original input-name
		var fi_file_name = fi_file.eq(i-1).attr('name');
		// Assign the name to the equivalent "fake input".
		$('.'+fi_container_class).eq(i-1).attr('data-name', fi_file_name);
	}

	// Button: action
	$('.'+fi_button_class).on('click', function() {
		// Get the name of the clicked "fake-input"
		var fi_active_input = $(this).parent().data('name');
		// Trigger "real input" with the equivalent input-name
		$('input[name='+fi_active_input+']').trigger('click');
	});

	// When the value of input changes
	fi_file.on('change', function() {
		// Variables
		var fi_file_name = $(this).val(); // Get the name and path of the chosen file
		var fi_real_name = $(this).attr('name'); // Get the name of changed input

		// Remove path from file-name
		var fi_array = fi_file_name.split('\\'); // Split on backslash (and escape it)
		var fi_last_row = fi_array.length - 1; // Deduct 1 due to 0-based index
			fi_file_name = fi_array[fi_last_row]; //

		// Loop through each "fake input container"
		$('.'+fi_container_class).each(function() {
			// Name of "this" fake-input
			var fi_fake_name = $(this).data('name');
			// If changed "fake button" is equal to the changed input-name
			if(fi_real_name == fi_fake_name) {
				// Add chosen file-name to the "fake input's label"
				$('.'+fi_container_class+'[data-name='+fi_real_name+'] .'+fi_filename_class).html(fi_file_name);
			}
		});
	});
}

//start
fileInput();


		 }
    },









	// tv-equipementlist page
    'page_template_template_industries_php': {
      init: function() {
        // JavaScript to be fired on the page-template-template-industries-php page

		$(window).resize(function() {
		var browserheight = $(window).height();
		/*var browserWidth = $(window).width();	*/

		$('.jumboimg').height($(window).height() - 180);
		/*$('#intro').css('width', browserWidth );*/
		});
	$(window).trigger('resize');
	//end



      }
    },


	// about_us
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about_us page

		$(window).resize(function() {
		var browserheight = $(window).height();
		/*var browserWidth = $(window).width();	*/

		$('.jumboimg').height($(window).height() - 180);
		/*$('#intro').css('width', browserWidth );*/
		});
	$(window).trigger('resize');
	//end



      }
    },

    // Process
      'process': {
        init: function() {
          // JavaScript to be fired on the about_us page



          particlesJS("particles-js", {

            "particles": {
              "number": {
                "value": 250,
                "density": {
                  "enable": true,
                  "value_area": 1500
                }
              },
              "color": {
                "value": "#fff"
              },
              "shape": {
                "type": "circle",
                "stroke": {
                  "width": 0,
                  "color": "#000000"
                },
                "polygon": {
                  "nb_sides": 5
                },
                "image": {
                  "src": "http://image.ibb.co/g9eFcF/logo_transparent.png",
                  "width": 100,
                  "height": 100
                }
              },
              "opacity": {
                "value": 1,
                "random": false,
                "anim": {
                  "enable": false,
                  "speed": 1,
                  "opacity_min": 0.6,
                  "sync": false
                }
              },
              "size": {
                "value": 3,
                "random": true,
                "anim": {
                  "enable": false,
                  "speed": 40,
                  "size_min": 0.1,
                  "sync": false
                }
              },
              "line_linked": {
                "enable": true,
                "distance": 120,
                "color": "#ffffff",
                "opacity": 0.5,
                "width": 1
              },
              "move": {
                "enable": true,
                "speed": 8,
                "direction": "random",
                "random": false,
                "straight": false,
                "out_mode": "out",
                "bounce": true,
                "attract": {
                  "enable": true,
                  "rotateX": 3600,
                  "rotateY": 3600
                }
              }
            },



            "interactivity": {
              "detect_on": "canvas",

              "events": {
                "onhover": {
                  "enable": true,
                  "mode": "grab"
                },

                "onclick": {
                  "enable": true,
                  "mode": "remove"
                },
                "resize": true
              },

              "modes": {
                "grab": {
                  "distance": 140,
                  "line_linked": {
                    "opacity": 1
                  }
                },
                "bubble": {
                  "distance": 200,
                  "size": 4,
                  "duration": 2,
                  "opacity": 1,
                  "speed": 3
                },

                "repulse": {
                  "distance": 200,
                  "duration": 0.5
                },

                "push": {
                  "particles_nb": 5
                },

                "remove": {
                  "particles_nb": 2
                }
              }
            },
            "retina_detect": true
          });



          // Affix Sub Navs
          /*
          var WinHeight = $(window).height();
          $(document).on("scroll", function() {
            if($(document).scrollTop()>WinHeight) {
              $(".affix_nav").addClass("fix");
            } else {
              $(".affix_nav").removeClass("fix");
            }
          });
          */

          $('.p_nav').click(function(){
        	    $('html, body').animate({
        	        scrollTop: $( $.attr(this, 'href') ).offset().top
        	    }, 500);
        	    return false;
        	});

          $('.btn-step-2').click(function() {
            $('.checkmark').addClass('ready');
          });

          $('.btn-more').click(function() {


            $('.p_s_img').removeClass('active');
            var thisID = $(this).data('rel');
            $('.'+thisID).addClass('active');

            if($(this).hasClass('collapsed')) {
              $(this).html('READ LESS');
            } else {
              $(this).html('READ MORE');
            }

          });


          // INVIEW Functions
          function inView( opt ) {
              if( opt.selector === undefined ) {
                  console.log( 'Valid selector required for inView' );
                  return false;
              }
              var elems = [].slice.call( document.querySelectorAll( opt.selector ) ),
                  once = opt.once === undefined ? true : opt.once,
                  offsetTop = opt.offsetTop === undefined ? 0 : opt.offsetTop,
                  offsetBot = opt.offsetBot === undefined ? 0 : opt.offsetBot,
                  count = elems.length,
                  winHeight = 0,
                  ticking = false;
              function update() {
                  var i = count;
                  while( i-- ) {
                      var elem = elems[ i ],
                          rect = elem.getBoundingClientRect();
                      if( rect.bottom >= offsetTop && rect.top <= winHeight - offsetBot ) {
                          elem.classList.add( 'in-view' );
                          if( once ) {
                              count--;
                              elems.splice( i, 1 );
                          }
                      } else {
                          elem.classList.remove( 'in-view' );
                      }
                  }
                  ticking = false;
              }
              function onResize() {
                  winHeight = window.innerHeight;
                  requestTick();
              }
              function onScroll() {
                  requestTick();
              }
              function requestTick() {
                  if( !ticking ) {
                      requestAnimationFrame( update );
                      ticking = true;
                  }
              }
              window.addEventListener( 'resize', onResize, false );
              document.addEventListener( 'scroll', onScroll, false );
              document.addEventListener( 'touchmove', onScroll, false );
              onResize();
          }
          // InView
          inView({
              selector: '.inview-item', // an .in-view class will get toggled on these elements
              once: false, // set this to false to have the .in-view class be toggled on AND off
              offsetTop: 0, // top threshold to be considered "in view"
              offsetBot: 200 // bottom threshold to be considered "in view"
          });

        }
      },









	};


  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
